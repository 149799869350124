<template>
  <v-app>
    <v-container
      fluid
      :class="{
        'main-container': true,
        'bg-dark': modoOscuro,
        'hiper-bg': !modoOscuro,
      }"
    >
      <v-row justify="center">
        <v-col cols="11" sm="11" md="10" lg="10">
          <v-card flat :color="modoOscuro ? '#001552' : 'white'">
            <router-view></router-view>
            <v-card-text class="text-center">
              <mainFooterComponent />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <appNotificationComponent />
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import { validarSesion } from "./mainTemplate.service";

import mainFooterComponent from "./mainFooter";
import appNotificationComponent from "./appNotification";

export default {
  name: "mainTemplate",
  components: { mainFooterComponent, appNotificationComponent },
  computed: {
    ...mapGetters(["sessionToken", "modoOscuro", "googleUser", "appleUser"]),
  },
  data: () => ({
    loading: true,
  }),
  created() {
    const tokenLocal = localStorage.getItem("hiperToken");
    if (tokenLocal) this.$store.dispatch("setSessionToken", tokenLocal);
  },
  async mounted() {
    if (!this.sessionToken) this.$router.push("/login");
    else {
      try {
        this.loading = true;
        const serverResponse = await validarSesion({});
        this.loading = false;
        this.$validateResponse(serverResponse);

        this.$store.dispatch("setUserId", serverResponse._id);
        this.$store.dispatch("setUserName", serverResponse.nombre);
        this.$store.dispatch("setUserAvatar", serverResponse.avatar);
        this.$store.dispatch("setGoogleUser", serverResponse.google);
        this.$store.dispatch("setAppleUser", serverResponse.apple);
        this.$store.dispatch("setModoOscuro", serverResponse.modoOscuroWeb);
      } catch (error) {
        this.loading = false;
        this.$appError(error.mensaje);
      }
    }
  },
};
</script>

<style>
.main-container {
  height: 100%;
  width: 100%;
}
.bg-dark {
  background-color: #000a39;
}
.ctn-dark {
  background-color: #001570;
}
.no-truncate {
  white-space: normal;
}

.hiper-bg {
  height: 100%;
  width: 100vw;
  background-color: #0d47a1;
  background-image: linear-gradient(10deg, #81d4fa 50%, transparent 50%),
    linear-gradient(-60deg, #5e35b1 30%, transparent 30%);
}
</style>
